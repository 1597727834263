<template>
  <div
    class="filter__container"
    @click.stop="NOOP"
  >
    <div class="filter__list">
      <FilterItem
        v-for="(menuItem, index) in menuList"
        :key="index"
        :menu-item="menuItem"
        :type="menuItem.name"
        :title="menuItem.title"
        :opened-menu="openedMenu"
        :select="select"
        :reset-search-query="resetSearchQuery"
        :is-show-search-btn="!!menuItem.search && !menuItem.search.value"
        @set-false-is-fixed-submit-button="setIsFixedSubmitButton(false)"
        @set-true-is-fixed-submit-button="setIsFixedSubmitButton(true)"
        @click="updateOpenedMenu(menuItem.name)"
      />
    </div>
    <FilterResetButton
      v-if="isEditFilter"
      @reset-click="reset"
    />
    <div
      class="filter__submit-wrapper"
      :class="{ 'filter__submit-wrapper_fixed': isFixedSubmitButton }"
    >
      <FilterSubmitButton
        :count="recipesCountText"
        @click="handleSubmit"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { debounce } from 'throttle-debounce';
  import { mapState } from 'pinia';
  import {
    DEBOUNCE_DURATION,
    MENU_TYPES,
    RESET_TYPES,
    FILTER_SELECTED_KEYS,
    MINIMAL_VALUE_LENGTH,
    WAIT_TIME_BETWEEN_MULTISELECT,
  } from './options';
  import type {
    Menu,
    MenuItem,
    SelectedMenuItem,
    MenuTime,
    SelectedMenuTime,
    Listener,
    TSelectHandler,
    MenuValues,
    FetchIngredientsPayload,
    ReturnIngredientsPayload,
    SearchInputQuery,
  } from './types';
  import FilterSubmitButton from '~/components/filter/FilterSubmitButton.vue';
  import FilterResetButton from '~/components/filter/FilterResetButton.vue';
  import FilterItem from '~/components/filter/FilterItem/FilterItem.vue';

  import { NOOP } from '~/utils';
  import {
    getPrefix,
    getSelected,
    handleTimeInputChange,
    mapSelectById,
    mapSelectedCb,
    prepareData,
    prepareTwice,
    resetSelected,
    handleCuisines,
    sortedbyAlphabet,
    movingElements,
    handleIncludedIngredientsChange,
    handleExcludedIngredientsChange,
    mapSelectByIdAnimation,
    create_UUID,
    movingIngredients,
    prepareTwiceDishes,
    currentListTwice,
    sortMenuItemAlphabetic,
    sortMenuItem,
    movingTwiceElements,
  } from '~/containers/FilterContainer/pureFunctions';

  import { TaskRunner } from '~/containers/FilterContainer/TaskRunner';
  import { useFilterStore } from '~/store/filter';

  export default defineNuxtComponent({
    name: 'FilterContainer',

    components: {
      FilterSubmitButton,
      FilterResetButton,
      FilterItem,
    },

    provide() {
      return {
        media: () => this.media,
      };
    },

    data: () => ({
      initialMenus: [],
      queuItems: [] as TSelectHandler[],
      localDishesSelectedListTwice: {
        listFirst: [] as MenuItem[],
        listLast: [] as MenuItem[],
      },
      localMenusSelectedListTwice: {
        listFirst: [] as MenuItem[],
        listLast: [] as MenuItem[],
      },
      cuisines: [] as MenuItem[],
      foundCuisines: [] as MenuItem[],
      foundIncludedIngredients: [] as MenuItem[],
      foundExcludedIngredients: [] as MenuItem[],
      localCookingMethodsSelectedListTwice: {
        listFirst: [] as MenuItem[],
        listLast: [] as MenuItem[],
      },

      cuisinesSearchQuery: '' as string,
      includedIngredientsSearchQuery: '' as string,
      excludedIngredientsSearchQuery: '' as string,

      includedIngredientsNotFound: false as boolean,
      excludedIngredientsNotFound: false as boolean,

      container_listeneres: [] as Listener[],
      phoneMedia: {} as MediaQueryList,
      tabletMedia: {} as MediaQueryList,
      desktopMedia: {} as MediaQueryList,
      taskRunner: {} as TaskRunner,
      uuid_includedIngredients: '' as string,
      uuid_excludedIngredients: '' as string,
      media: null as 'mobile' | 'tablet' | 'desktop' | null,
    }),

    async setup() {
      const filterStore = useFilterStore();
      const route = useRoute();
      const isFilterPage = route.path.includes('filter');

      const dishes = ref<MenuItem[]>([]);
      const menus = ref<MenuItem[]>([]);
      const cookingMethods = ref<MenuItem[]>([]);
      const time = ref<MenuTime[]>([]);

      const selectedIncludedIngredients = ref([]);
      const selectedExcludedIngredients = ref([]);
      const selectedCuisines = ref<SelectedMenuItem[]>([]);
      const selectedDishes = computed(() => {
        return dishes.value.filter(getSelected) as SelectedMenuItem[];
      });
      const selectedMenus = computed(() => {
        return menus.value.filter(getSelected) as SelectedMenuItem[];
      });
      const selectedCookingMethods = computed(() => {
        return cookingMethods.value.filter(getSelected) as SelectedMenuItem[];
      });
      const selectedTime = computed(() => {
        return time.value.filter((item) => item.selected && item.slug.length) as SelectedMenuTime[];
      });

      const filterValuesMap = computed(
        () =>
          ({
            selectedDishes,
            selectedCuisines,
            selectedIncludedIngredients,
            selectedExcludedIngredients,
            selectedMenus,
            selectedCookingMethods,
            selectedTime,
          }) as const,
      );

      // url для открытия страницы с отфильтрованными рецептами
      const filterUrl = computed(() => {
        let url = '';

        FILTER_SELECTED_KEYS.forEach((type) => {
          const prefix = getPrefix(type);

          if (type !== 'selectedTime') {
            filterValuesMap.value[type].value.forEach(
              (item: SelectedMenuItem | SelectedMenuTime, index: number): void => {
                url += item.slug ? `${index === 0 ? prefix : ''}${item.slug}/` : '';
              },
            );
          } else {
            const lastTime: SelectedMenuTime = filterValuesMap.value[type].value.slice(-1)[0];
            if (lastTime) {
              url += lastTime.slug ? `${lastTime.slug}/` : '';
            }
          }
        });

        return url.length ? `/recipes/filter/${url}` : url;
      });

      const { data: asyncData, error } = await useAsyncData(
        `FILTER_CONTAINER_ASYNC_DATA`,
        async () => {
          const [menus, filterSettings] = await Promise.all([
            filterStore.fetchMenus(),
            isFilterPage ? filterStore.fetchFilterSettings(route.path) : null,
          ]);

          return { menus, filterSettings };
        },
      );

      if (error.value) {
        console.error('Error in FilterContainer.vue:', error.value);
      }

      return {
        time,
        dishes,
        menus,
        cookingMethods,
        selectedIncludedIngredients,
        selectedExcludedIngredients,
        selectedCuisines,
        selectedDishes,
        selectedMenus,
        selectedCookingMethods,
        selectedTime,

        filterStore,
        filterUrl,

        asyncData,
      };
    },

    created() {
      const { menus, filterSettings } = this.asyncData ?? {};

      if (menus) {
        this.initialMenus = JSON.parse(JSON.stringify(menus));
        this.setFilterMenus(menus);
      }

      if (filterSettings) {
        this.setFilterSettings(filterSettings);
      }

      this.filterStore.setOpenedMenu(MENU_TYPES.DISHES);
    },

    computed: {
      ...mapState(useFilterStore, [
        'recipesCount',
        'isFixedSubmitButton',
        'openedMenu',
        'animate',
        'sortDirection',
        'dishesTwice',
      ]),

      menuList(): Menu[] {
        return [
          {
            name: MENU_TYPES.DISHES,
            title: 'Блюда',
            selectedList: this.selectedDishes,
            openedList: this.dishes,
            openedListTwice: this.localDishesSelectedListTwice,
            search: null,
            twice: this.dishesTwice,
            animate: this.animate.dishes,
          },
          {
            name: MENU_TYPES.MENUS,
            title: 'Меню',
            selectedList: this.selectedMenus,
            openedList: this.menus,
            openedListTwice: this.localMenusSelectedListTwice,
            search: null,
            twice: true,
            animate: true,
          },
          {
            name: MENU_TYPES.CUISINES,
            title: 'Национальная кухня',
            selectedList: this.selectedCuisines,
            openedList: this.showCuisines,
            search: {
              value: this.cuisinesSearchQuery,
              handleInput: this.handleInputCuisines,
              showInstructions: false,
              notFound: !this.foundCuisines.length,
            },
            twice: false,
            animate: this.animate.cuisines,
          },
          {
            name: MENU_TYPES.INCLUDEDINGREDIENTS,
            title: 'Добавить ингредиенты',
            selectedList: this.selectedIncludedIngredients,
            openedList: this.showIncludedIngredients,
            search: {
              value: this.includedIngredientsSearchQuery,
              handleInput: this.handleInputIncludedIngredients,
              showInstructions: true,
              notFound: this.includedIngredientsNotFound,
              minValueLength: MINIMAL_VALUE_LENGTH,
            },
            twice: false,
            animate: this.animate.includedIngredients,
          },
          {
            name: MENU_TYPES.EXCLUDEDINGREDIENTS,
            title: 'Исключить ингредиенты',
            selectedList: this.selectedExcludedIngredients,
            openedList: this.showExcludedIngredients,
            search: {
              value: this.excludedIngredientsSearchQuery,
              handleInput: this.handleInputExcludedIngredients,
              showInstructions: true,
              notFound: this.excludedIngredientsNotFound,
              minValueLength: MINIMAL_VALUE_LENGTH,
            },
            twice: false,
            animate: this.animate.excludedIngredients,
          },
          {
            title: 'Способ приготовления',
            name: MENU_TYPES.COOKINGMETHODS,
            selectedList: this.selectedCookingMethods,
            openedList: this.cookingMethods,
            openedListTwice: this.localCookingMethodsSelectedListTwice,
            search: null,
            twice: true,
            animate: true,
          },
          {
            title: 'Время',
            name: MENU_TYPES.TIME,
            selectedList: this.selectedTime,
            openedList: this.time,
            search: null,
            twice: false,
            animate: false,
          },
        ];
      },

      redirectUrl(): string {
        const taxonomyNames = ['selectedDishes', 'selectedCuisines', 'selectedCookingMethods'];

        const activeFilters = FILTER_SELECTED_KEYS.flatMap(
          (type) => this[type] as SelectedMenuItem[],
        );

        const activeTaxonomyFilters = FILTER_SELECTED_KEYS.filter((type) =>
          taxonomyNames.includes(type),
        ).flatMap((type) => this[type] as SelectedMenuItem[]);

        const isActiveOnlyTaxonomy =
          activeFilters.length === 1 && activeTaxonomyFilters.length === 1;

        if (isActiveOnlyTaxonomy) {
          const { taxonomy, slug } = activeTaxonomyFilters[0] as SelectedMenuItem;
          return `/recipes/${taxonomy}/${slug}`;
        }

        return this.filterUrl;
      },

      filterCount(): number {
        return FILTER_SELECTED_KEYS.flatMap((type) => this[type]).length;
      },

      isEditFilter(): boolean {
        return this.filterUrl.length > 0;
      },

      showCuisines(): MenuItem[] {
        // На десктопе если в поиске что-то нашли, то показываем только результаты поиска
        if (this.foundCuisines.length && this.media === 'desktop') {
          return this.foundCuisines;
        }

        const uniqueSelectedCuisines = this.selectedCuisines.filter(
          (selectedItem) => !this.foundCuisines.some((item) => item.id === selectedItem.id),
        );

        const sortByTimestamp = (a: MenuItem, b: MenuItem) => {
          return (b.selectedTimestamp || 0) - (a.selectedTimestamp || 0);
        };

        // Если на мобилке или ничего не нашли, то показываем уже выбранные + потом найденные
        return [...uniqueSelectedCuisines, ...this.foundCuisines].sort(sortByTimestamp);
      },

      showIncludedIngredients(): MenuItem[] {
        if (this.includedIngredientsSearchQuery.length >= MINIMAL_VALUE_LENGTH) {
          return this.foundIncludedIngredients;
        }
        return this.selectedIncludedIngredients;
      },

      showExcludedIngredients(): SelectedMenuItem[] | MenuItem[] {
        if (this.excludedIngredientsSearchQuery.length >= MINIMAL_VALUE_LENGTH) {
          return this.foundExcludedIngredients;
        }
        return this.selectedExcludedIngredients;
      },

      debouncedSearchIngredients(): any {
        return debounce(DEBOUNCE_DURATION, async (payload: FetchIngredientsPayload) => {
          const { items, type }: ReturnIngredientsPayload =
            await this.filterStore.fetchIngredients(payload);

          if (type === 'included') {
            this.setIncludedIngredients({ items });
            return;
          }
          this.setExcludedIngredients({ items });
        });
      },
      debounceAnimatedAll(): any {
        return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => {
          return this.doQueuItem(movingElements);
        });
      },
      debounceAnimated(): any {
        return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => this.doQueuItem(movingElements));
      },
      debounceAnimatedTwice(): any {
        return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () => this.doQueuItem(movingTwiceElements));
      },
      debounceAnimatedIncludedIngredients(): any {
        return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () =>
          this.doQueuItemIncludedIngredients(movingIngredients),
        );
      },
      debounceAnimatedExcludedIngredients(): any {
        return debounce(WAIT_TIME_BETWEEN_MULTISELECT, () =>
          this.doQueuItemExcludedIngredients(movingIngredients),
        );
      },
      debounce_FETCH_RECIPES_COUNT(): any {
        return debounce(WAIT_TIME_BETWEEN_MULTISELECT, (payload: { url: string }) =>
          this.filterStore.fetchRecipesCount(payload.url),
        );
      },
      debounce_ACTION_HANDLER_PHONE(): any {
        return debounce(250, (payload: boolean) => {
          if (payload) {
            this.filterStore.actionHandlerPhone();
          }
        });
      },
      debounce_ACTION_HANDLER_TABLET(): any {
        return debounce(250, (payload: boolean) => {
          if (payload) {
            this.filterStore.actionHandlerTablet();
          }
        });
      },
      debounce_ACTION_HANDLER_DESKTOP(): any {
        return debounce(250, (payload: boolean) => {
          if (payload) {
            this.filterStore.actionHandlerDesktop();
          }
        });
      },
      isMobileWidth(): boolean {
        return this.phoneMedia.matches || this.tabletMedia.matches;
      },
      recipesCountText(): string {
        const result = this.recipesCount > 9999 ? '9999+' : this.recipesCount;
        return result ? `(${result})` : '';
      },
    },

    watch: {
      media() {
        this.updateCuisines();
      },

      filterCount(newVal: number): void {
        this.$emit('filterCount', newVal);
      },
      filterUrl(newVal: string): void {
        this.debounce_FETCH_RECIPES_COUNT?.({ url: newVal });
      },

      isEditFilter(newVal: boolean): void {
        if (!newVal) {
          this.filterStore.setRecipesCount(0);
        }
      },

      includedIngredientsSearchQuery(searchValue: string): void {
        if (searchValue && searchValue.length >= MINIMAL_VALUE_LENGTH) {
          this.debouncedSearchIngredients({
            searchValue,
            type: 'included',
          });
        }
      },

      excludedIngredientsSearchQuery(searchValue: string): void {
        if (searchValue && searchValue.length >= MINIMAL_VALUE_LENGTH) {
          this.debouncedSearchIngredients({
            searchValue,
            type: 'excluded',
          });
        }
      },
    },

    mounted(): void {
      this.addEventListeners();
      this.taskRunner = new TaskRunner();
    },

    unmounted() {
      this.removeEventListeners();
    },

    methods: {
      setIsFixedSubmitButton(value: boolean) {
        this.filterStore.setIsFixedSubmitButton(value);
      },

      getFoundedCuisines(search: string, cuisines: MenuItem[]): MenuItem[] {
        return search === ''
          ? cuisines.concat()
          : cuisines.filter((item) => {
              const lowerTitle = item.title.toLocaleLowerCase();
              const lowerSearch = this.cuisinesSearchQuery.toLocaleLowerCase();

              return lowerTitle.startsWith(lowerSearch);
            });
      },

      doQueuItem(func: typeof movingElements | typeof movingTwiceElements): void {
        const vm = this;
        while (this.queuItems.length) {
          const { type, id, row, animateCb } = this.queuItems.shift();
          this.taskRunner.add({
            fn: (...args: any[]): Promise<any> => {
              const [, type, id, , cb] = args;
              vm[type] = mapSelectByIdAnimation(vm[type], id);
              if (func === movingElements) {
                let list = vm[type];

                if (row) {
                  list = currentListTwice({
                    menuList: this.menuList,
                    type,
                    row,
                  });
                }
                return func(vm, list, id, cb);
              }
              return func(vm, ...args);
            },
            args: [this.menuList, type, id, row, animateCb],
          });
        }
      },
      doQueuItemIncludedIngredients(func: Function): void {
        const vm = this;
        while (this.queuItems.length) {
          const { id, animateCb, uuid_includedIngredients } = this.queuItems.shift();
          this.taskRunner.add({
            fn: (...args: any[]): Promise<any> => {
              const [data, id, animateCb, uuid_includedIngredients] = args;
              const itemSelected = vm.selectedIncludedIngredients.find(
                (item: any) => item.id === id,
              );
              const itemFound = vm.foundIncludedIngredients.find((item: any) => item.id === id);
              if (itemSelected) {
                vm.selectedIncludedIngredients = vm.selectedIncludedIngredients.filter(
                  (item: any) => item.id !== id,
                );
              } else {
                vm.selectedIncludedIngredients.unshift({ ...itemFound, selected: true });
              }
              itemFound.selected = !itemFound.selected;
              itemFound.isLockAnimation = false;
              return func(vm, data, id, animateCb, uuid_includedIngredients === itemFound.uuid);
            },
            args: [vm.foundIncludedIngredients, id, animateCb, uuid_includedIngredients],
          });
        }
      },
      doQueuItemExcludedIngredients(func: Function): void {
        const vm = this;
        while (this.queuItems.length) {
          const { id, animateCb, uuid_excludedIngredients } = this.queuItems.shift();
          this.taskRunner.add({
            fn: (...args: any[]): Promise<any> => {
              const [data, id, animateCb, uuid_excludedIngredients] = args;
              const itemSelected = vm.selectedExcludedIngredients.find(
                (item: any) => item.id === id,
              );
              const itemFound = vm.foundExcludedIngredients.find((item: any) => item.id === id);
              if (itemSelected) {
                vm.selectedExcludedIngredients = vm.selectedExcludedIngredients.filter(
                  (item: any) => item.id !== id,
                );
              } else {
                vm.selectedExcludedIngredients.unshift({ ...itemFound, selected: true });
              }
              itemFound.selected = !itemFound.selected;
              itemFound.isLockAnimation = false;
              return func(this, data, id, animateCb, uuid_excludedIngredients === itemFound.uuid);
            },
            args: [vm.foundExcludedIngredients, id, animateCb, uuid_excludedIngredients],
          });
        }
      },
      NOOP,
      setFilterMenus(menuProp: any): void {
        if (!menuProp) {
          return;
        }

        const { cuisines, cookingMethods, menus, dishes, time } = menuProp;

        this.cuisines = prepareData(cuisines);
        this.foundCuisines = this.cuisines.concat();
        this.cookingMethods = prepareData(cookingMethods);
        prepareTwice(this.localCookingMethodsSelectedListTwice, this.cookingMethods);
        this.menus = prepareData(menus);
        prepareTwice(this.localMenusSelectedListTwice, this.menus);
        const sorted = sortedbyAlphabet(dishes);
        this.dishes = prepareData(sorted);
        prepareTwiceDishes(this.localDishesSelectedListTwice, this.dishes);

        // @ts-ignore
        this.time = prepareData(time);
      },

      // установка параметров фильтра при старте приложения на
      // странице с отфильтрованными результатами
      setFilterSettings(data: any): void {
        if (!data) {
          return;
        }

        const {
          cuisines,
          cookingMethods,
          menus,
          dishes,
          time,
          includeIngredients,
          excludeIngredients,
        } = data;

        if (time) {
          const indexTime = this.time.findIndex((item) => item.id === time);

          this.time = this.time.map((item, index) => ({
            ...item,
            selected: index <= indexTime,
          }));
        }

        this.selectedIncludedIngredients.push(...includeIngredients);
        this.selectedIncludedIngredients = this.selectedIncludedIngredients.map(
          mapSelectedCb(true),
        );
        this.selectedExcludedIngredients.push(...excludeIngredients);
        this.selectedExcludedIngredients = this.selectedExcludedIngredients.map(
          mapSelectedCb(true),
        );

        cuisines.forEach((id: string) => {
          this.cuisines = mapSelectById(this, this.cuisines, id, true);
        });

        this.selectedCuisines = cuisines.map((id: string) => {
          const defaultSelectedCuisine = this.cuisines.find(
            (cuisine: MenuItem) => cuisine.id === id,
          );

          return {
            ...defaultSelectedCuisine,
            selected: true,
          };
        });

        cookingMethods.forEach((id: string) => {
          this.cookingMethods = mapSelectById(this, this.cookingMethods, id, true);
        });

        menus.forEach((id: string) => {
          this.menus = mapSelectById(this, this.menus, id, true);
        });

        dishes.forEach((id: string) => {
          this.dishes = mapSelectById(this, this.dishes, id, true);
        });
      },
      setExcludedIngredients({ items }: { items: MenuItem[] }): void {
        const uuid = create_UUID();
        this.uuid_excludedIngredients = uuid;
        const preparedItems = prepareData(items).map((el) => ({
          ...el,
          uuid,
        })) as SelectedMenuItem[];
        this.excludedIngredientsNotFound = !preparedItems.length;
        const selectedIds = this.selectedExcludedIngredients.map((el) => el.id);
        const filtered = preparedItems.filter((item) => !selectedIds.includes(item.id));
        if (filtered.length === 0) {
          this.foundExcludedIngredients =
            this.sortDirection === 'asc'
              ? filtered.concat(this.selectedExcludedIngredients)
              : (this.selectedExcludedIngredients as MenuItem[]).concat(filtered);

          return;
        }
        this.foundExcludedIngredients =
          this.media === 'desktop'
            ? filtered
            : (this.selectedExcludedIngredients as MenuItem[]).concat(filtered);
      },
      setIncludedIngredients({ items }: { items: MenuItem[] }): void {
        const uuid = create_UUID();
        this.uuid_includedIngredients = uuid;
        const preparedItems = prepareData(items).map((el) => ({
          ...el,
          uuid,
        })) as SelectedMenuItem[];
        this.includedIngredientsNotFound = !preparedItems.length;
        const selectedIds = this.selectedIncludedIngredients.map((el) => el.id);
        const filtered = preparedItems.filter((item) => !selectedIds.includes(item.id));

        if (filtered.length === 0) {
          this.foundIncludedIngredients =
            this.sortDirection === 'asc'
              ? filtered.concat(this.selectedIncludedIngredients)
              : (this.selectedIncludedIngredients as MenuItem[]).concat(filtered);

          return;
        }

        this.foundIncludedIngredients =
          this.media === 'desktop'
            ? filtered
            : (this.selectedIncludedIngredients as MenuItem[]).concat(filtered);
      },
      // deprecated
      setCuisines(items: MenuItem[]): void {
        const selectedIds = this.selectedCuisines.map((el) => el.id);
        const filtered = items.filter((item) => !selectedIds.includes(item.id));

        if (filtered.length === 0) {
          this.foundCuisines = filtered;
          return;
        }

        this.foundCuisines = this.cuisinesSearchQuery.length
          ? filtered
          : (this.selectedCuisines as MenuItem[]).concat(filtered);
      },
      findMenuListByName(type: string) {
        return this.menuList.find((el) => el.name === type);
      },

      select(options: TSelectHandler): void {
        this.$emit('used-filter');
        const menu = this.findMenuListByName(options.type);
        if (!menu) {
          return;
        }
        const condition = typeof options.animateCb === 'function' && menu.animate;
        condition ? this.selectHaveAnimate(options) : this.selectNoAnimate(options);
      },

      selectHaveAnimate(options: TSelectHandler): void {
        const { type, id } = options;
        if (
          [MENU_TYPES.MENUS, MENU_TYPES.COOKINGMETHODS, MENU_TYPES.DISHES].find((el) => el === type)
        ) {
          const menu = this.findMenuListByName(type);
          if (!menu) {
            return;
          }
          this.queuItems.push(options);
          this.debounceAnimatedAll();
        } else if (type === MENU_TYPES.CUISINES) {
          handleCuisines(this, id);
        } else if (type === MENU_TYPES.INCLUDEDINGREDIENTS) {
          this.queuItems.push({
            ...options,
            uuid_includedIngredients: this.uuid_includedIngredients,
          });
          this.debounceAnimatedIncludedIngredients();
        } else if (type === MENU_TYPES.EXCLUDEDINGREDIENTS) {
          this.queuItems.push({
            ...options,
            uuid_excludedIngredients: this.uuid_excludedIngredients,
          });
          this.debounceAnimatedExcludedIngredients();
        } else if (type === MENU_TYPES.TIME) {
          this.time = handleTimeInputChange(this, id);
        }
      },
      selectNoAnimate(options: TSelectHandler) {
        const { type, id } = options;
        if (
          [MENU_TYPES.MENUS, MENU_TYPES.COOKINGMETHODS, MENU_TYPES.DISHES].find((el) => el === type)
        ) {
          // @ts-ignore
          this[type] = mapSelectById(this, this[type], id, undefined);
        } else if (type === MENU_TYPES.CUISINES) {
          handleCuisines(this, id, false);
        } else if (type === MENU_TYPES.INCLUDEDINGREDIENTS) {
          handleIncludedIngredientsChange(this, id, false);
        } else if (type === MENU_TYPES.EXCLUDEDINGREDIENTS) {
          handleExcludedIngredientsChange(this, id, false);
        } else if (type === MENU_TYPES.TIME) {
          this.time = handleTimeInputChange(this, id);
        }
      },
      reset() {
        if (this.isMobileWidth) {
          this.resetFilterTablet();
          return;
        }
        this.resetFilter();
      },
      resetFilterTablet(): void {
        this.selectedCuisines = [];
        this.selectedIncludedIngredients = [];
        this.selectedExcludedIngredients = [];
        this.foundIncludedIngredients = [];
        this.foundExcludedIngredients = [];
        this.resetSearchQuery();
        this.menuList
          .filter((el) => el.name === MENU_TYPES.DISHES && !el.twice)
          .forEach((menuItem) => {
            (menuItem.openedList as any[])
              ?.filter((el): boolean => {
                return !!el.selected;
              })
              .forEach((el: MenuItem | MenuTime) => {
                el.selected = false;
                el.selectedTimestamp = null;
              });
            (menuItem.openedList as any[]).sort(sortMenuItemAlphabetic());
          });
        this.menuList
          .filter((el) => el.twice)
          .forEach((menuItem) => {
            (menuItem.openedListTwice?.listFirst as any[])
              .filter((el) => el.selected)
              .forEach((el: MenuItem | MenuTime) => {
                el.selected = false;
                el.selectedTimestamp = null;
              });
            (menuItem.openedListTwice?.listFirst as any[]).sort(sortMenuItemAlphabetic());

            (menuItem.openedListTwice?.listLast as any[])
              .filter((el) => el.selected)
              .forEach((el: MenuItem | MenuTime) => {
                el.selected = false;
                el.selectedTimestamp = null;
              });
            (menuItem.openedListTwice?.listLast as any[]).sort(sortMenuItemAlphabetic());
          });
        ['time'].forEach((type) => {
          // @ts-ignore
          const item = this[type];
          // @ts-ignore
          this[type] = item.map(resetSelected);
        });
        this.foundCuisines = this.cuisines
          .map((item: MenuItem) => {
            item.selected = false;
            item.selectedTimestamp = null;
            item.isLockAnimation = false;
            return item;
          })
          .sort(sortMenuItemAlphabetic());

        this.setFilterMenus(this.initialMenus);
      },
      resetFilter(): void {
        this.menuList
          .filter((el) => el.twice)
          .forEach((menuItem) => {
            (menuItem.openedListTwice?.listFirst as any[])
              .filter((el): boolean => {
                return !!el.selected;
              })
              .forEach((el: MenuItem | MenuTime) => {
                this.select({ type: menuItem.name, id: el.id, row: 'first' });
              });
            (menuItem.openedListTwice?.listLast as any[])
              .filter((el) => el.selected)
              .forEach((el: MenuItem | MenuTime) => {
                this.select({ type: menuItem.name, id: el.id, row: 'last' });
              });
          });
        (this.menuList.find((el) => el.name === MENU_TYPES.DISHES)?.openedList as any[])
          ?.filter((el) => el.selected)
          .forEach((el: MenuItem | MenuTime) => {
            this.select({ type: MENU_TYPES.DISHES, id: el.id });
          });
        RESET_TYPES.forEach((type) => {
          const item = this[type];
          // @ts-ignore
          this[type] = item.map(resetSelected);
        });

        this.selectedCuisines = [];
        this.selectedIncludedIngredients = [];
        this.selectedExcludedIngredients = [];
        this.foundIncludedIngredients = [];
        this.foundExcludedIngredients = [];

        this.cuisines = this.cuisines.map(mapSelectedCb(false));

        this.resetSearchQuery();

        this.$emit('used-filter');
      },

      resetSearchQuery(type?: SearchInputQuery): void {
        switch (type) {
          case 'cuisines':
            this.cuisinesSearchQuery = '';
            this.updateCuisines();
            return;
          case 'includedIngredients':
            this.includedIngredientsSearchQuery = '';
            return;
          case 'excludedIngredients':
            this.excludedIngredientsSearchQuery = '';
            return;
          default:
            this.cuisinesSearchQuery = '';
            this.updateCuisines();
            this.includedIngredientsSearchQuery = '';
            this.excludedIngredientsSearchQuery = '';
        }
      },

      handleInputCuisines(event: InputEvent): void {
        this.cuisinesSearchQuery = (event.target as HTMLInputElement).value;
        this.updateCuisines();
      },

      updateCuisines() {
        const foundedItems = this.getFoundedCuisines(this.cuisinesSearchQuery, this.cuisines);

        if (this.media !== 'desktop') {
          foundedItems.sort(sortMenuItem());
        }

        this.foundCuisines = foundedItems;
      },
      handleInputIncludedIngredients(event: InputEvent): void {
        this.includedIngredientsSearchQuery = (event.target as HTMLInputElement).value;
      },

      handleInputExcludedIngredients(event: InputEvent): void {
        this.excludedIngredientsSearchQuery = (event.target as HTMLInputElement).value;
      },

      updateOpenedMenu(value: MenuValues | ''): void {
        if (this.isMobileWidth) {
          return;
        }

        this.foundIncludedIngredients = [];
        this.foundExcludedIngredients = [];

        const isAlreadyOpen = this.openedMenu === value;
        this.filterStore.setOpenedMenu(isAlreadyOpen ? '' : value);

        if (value === 'cuisines') {
          this.media === 'desktop'
            ? this.selectedCuisines.sort(sortMenuItemAlphabetic())
            : this.selectedCuisines.sort(sortMenuItem());
        }

        this.resetSearchQuery();

        this.$emit('used-filter');
      },

      async handleSubmit(): Promise<void> {
        if (this.isEditFilter && Boolean(this.recipesCount)) {
          await this.$nextTick();
          location.href = this.redirectUrl;
          this.updateOpenedMenu('');
          this.resetSearchQuery();
          this.$emit('update-url');
          this.$emit('used-filter');
        }
      },

      removeEventListeners(): void {
        while (this.container_listeneres.length > 0) {
          const item = this.container_listeneres.shift();
          item?.el.removeEventListener('change', item?.handler);
        }
      },

      addEventListeners(): void {
        const handlerPhone = (e: MediaQueryList | MediaQueryListEvent): void => {
          this.debounce_ACTION_HANDLER_PHONE(e.matches);
          if (e.matches) {
            this.media = 'mobile';
          }
        };
        const phoneMedia = window.matchMedia('(max-width: 767px)');
        this.phoneMedia = phoneMedia;
        handlerPhone(phoneMedia);
        phoneMedia.addEventListener('change', handlerPhone);
        const handlerTablet = (e: MediaQueryList | MediaQueryListEvent): void => {
          this.debounce_ACTION_HANDLER_TABLET(e.matches);
          if (e.matches) {
            this.media = 'tablet';
          }
        };
        const tabletMedia = window.matchMedia('(min-width: 768px) and (max-width: 1023px)');
        this.tabletMedia = tabletMedia;
        handlerTablet(tabletMedia);
        tabletMedia.addEventListener('change', handlerTablet);

        const handlerDesktop = (e: MediaQueryList | MediaQueryListEvent): void => {
          this.debounce_ACTION_HANDLER_DESKTOP(e.matches);
          if (e.matches) {
            this.media = 'desktop';
          }
        };
        const desktopMedia = window.matchMedia('(min-width: 1024px)');
        this.desktopMedia = desktopMedia;
        handlerDesktop(desktopMedia);
        desktopMedia.addEventListener('change', handlerDesktop);

        this.container_listeneres.push(
          { el: phoneMedia, handler: handlerPhone },
          { el: tabletMedia, handler: handlerTablet },
          { el: desktopMedia, handler: handlerDesktop },
        );
      },
    },
  });
</script>

<style lang="scss" scoped>
  $textColor: black;
  $border-color: #e7e7e7;
  $backgroundColor: white;

  .filter {
    $root: &;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      -ms-overflow-style: none;
      scrollbar-width: none;
      max-height: 100%;
      height: 100%;

      @include big-tablet() {
        max-height: calc(100vh - 88px);
        height: auto;
        padding-bottom: 0;
      }
    }

    &__submit-wrapper {
      display: none;
      width: 100%;
      padding: 8px 16px 16px 16px;
      left: 0;
      bottom: 0;
      border-top: 1px solid $border-color;
      z-index: 3;
      background-color: white;
      user-select: none;

      @include big-tablet() {
        position: sticky;
        padding: 8px 15px 16px 16px;
        display: block;
        padding-bottom: 40px;
      }

      @include desktop {
        position: relative;
      }
    }

    &__list {
      flex-grow: 1;
      overflow-y: scroll;
      padding: 0 0 8px 0;
      @include hideScrollBar;

      &::-webkit-scrollbar {
        display: none;
      }

      @include big-tablet {
        padding-top: 8px;
      }

      @include desktop {
        flex-grow: 0;
      }
    }
  }

  .filter_opened {
    .filter__submit-wrapper {
      display: block;
    }
  }
  .container__once {
    width: calc(100vw - 16px * 2);
    display: flex;
    white-space: nowrap;
    gap: 16px;
    &:has(.input__label) {
      overflow-x: scroll;
      scrollbar-width: none;
    }
  }

  .container__twice {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .container__first,
    .container__last {
      width: calc(100vw - 16px * 2);
      display: flex;
      white-space: nowrap;
      gap: 16px;
      &:has(.input__label) {
        overflow-x: scroll;
        scrollbar-width: none;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .drop-down__closed {
    .container__twice {
      gap: initial;
    }
  }
</style>
